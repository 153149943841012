import {Component} from 'react'
import { alias, determineLocale } from 'src/utilities/locale'

/**
 * Prefix the requested path with the current locale
 */
export default class LocalizedRedirect extends Component {
  componentDidMount = async() => {
    const locale = await determineLocale()
    window.location.replace(`/${alias(locale)}${window.location.pathname}${window.location.search||''}`)
  }

  render = () => false
}


// export default LocalizedRedirect